
body {
  font-family: sans-serif;
}

h2.bold {
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
}

.header-links {
  margin-top: 0;
  padding: 16px;
}

.header-links li {
    position: relative;
    list-style-type: none;
    border: 15px solid transparent;
    border-bottom: none;
    border-top: none;
}

.header-links a {
    color: #fff;
    text-decoration: none;
}

.header-links a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); 
}

.header-links a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #a7b0fe;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all .3s ease-in-out 0s;
    transition: all .3s ease-in-out 0s;
    top: 48px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/

.App-main {
  text-align: left;
}

.App-link {
  color: #61dafb;
}

.App-button {
  color: #61dafb;
  font-size: calc(10px + 2vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

amplify-authenticator {
  background-color: #282c34;
  min-width: 100%;
  --container-height: 80vh;
}

amplify-container {
  height:80vh;
}

.App-header {
  background-image: linear-gradient(to right,#4f62fe,#3b16a5);
  border-bottom: 2px solid #a7b0fe;
  text-align: right;
}

.App-header ul li {
  display: inline;
  /* margin:10px; */
}

.PricingTableContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}

.PricingTable {
  border-radius: 16px;
  overflow: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.PricingTable, .PricingTable td, .PricingTable th {
  border: 1px solid #CCC;
}

.PricingTable>table {
  table-layout: fixed;
  border-collapse: collapse;
  border-style: hidden;
}

.PricingTable td, .PricingTable th {
  word-wrap: break-word;
  max-width: 350px;
  min-width: 120px;
}

.bold {
  font-weight: 600;
}

.PlanFlag>* {
  width: 100%;
  text-align: center;
  background-color: rgba(13,147,242,0.12);
  color: #000;
  display: block;
  margin:0;
  padding:0;
}

.PlanFlag {
  line-height: 30px;
}

.SubscribedIndicator>* {
  display: block;
}

.caption {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.FirstColoumnCell {
  padding: 8px 8px;
}

.PlanHeader, .PlanDetailCell {
  text-align: center;
  padding: 16px 4px;
}

.PlanName {
  font-weight: 100;
}

.btn-primary {
  line-height: 1;
  display: inline;
  color: #49cc90;
  border: 2px solid #49cc90;
  background-color: transparent;
  font-weight: 700;
}

.btn {
  cursor: pointer;
}

.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.PaymentStripeForm {
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PaymentPayButton {
  margin: 10px;
  padding: 5px;
  min-width: 200px;
}

.PaymentContainer {
  text-align: center;
}

.StripeElement {
  display: block;
  margin: 7px auto;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.DashboardContainer, .PaymentContainer {
  padding-top: 30px;
}

.DashboardElements {
  min-width: 800px;
  max-width: 1400px;
  margin: 0 auto;
}

.DashboardChart {
  margin: 0 auto;
  min-width: 800px;
  max-width: 1460px;
  min-height: 400px;
  max-height: 800px;
}

.DashboardElements > * {
  padding: 5px 0;
}

.DashboardButtons > * {
  margin-right: 5px;
}

.AppHiddenElement {
  display: none;
}

.AppFooter {
  padding: 10px;
}

.TutorialArticle, .PrivacyArticle {
  margin: 0 auto;
  max-width: 800px;
}

.TutorialVideo {
  position: relative;
  padding-bottom: 52.4%;
  padding-top: 30px; 
  height: 0; 
  overflow: hidden;
  border: 1px solid #EEE;
}

.TutorialVideo iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Specification {
  max-width: 1420px;
  margin: auto;
  padding-top: 20px;
}